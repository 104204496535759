import * as React from "react"

import {
  useWindowWidth,
} from '@react-hook/window-size'

import DiscordImage from "../images/discord.png"
import FerrariImage from "../images/ferrari.png"
import GarageLogo from "../images/logo.png"
import GT40FrointImage from "../images/gt40froint.png"
import InteriorImage from "../images/interior.png"

// styles
const pageStyles = {
  padding: 0,
  margin: 0,
  color: "#232129",
}

const primaryTitleTextStyle = {
  marginLeft: 16,
  color: "#6b0608",
  fontSize: "76px",
  fontWeight: 700,
  letterSpacing: 2,
}

const primaryTitleTextMobileStyle = {
  fontSize: "32px",
}

const secondaryTitleTextStyle = {
  color: "#111830",
  fontSize: "36px",
  fontWeight: 700,
  lineHeight: "49px",
  letterSpacing: 1,
}

const secondaryTitleMobileTextStyle = {
  fontSize: "30px",
}

const contentTextStyle = {
  marginTop: 24,
  color: "#400404",
  fontSize: "24px",
  fontWeight: 300,
  lineHeight: "33px",
  letterSpacing: 1,
}

const sectionStyle = {
  display: "flex",
  width: "100%",
  // minHeight: 640,
}

const sectionMobileStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "none",
}

const titleContainerStyle = {
  display: "flex",
  alignItems: "center",
}

const leftTextSectionStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "64px",
  paddingBottom: "64px",
}

const leftTextSectionMobileStyle = {
  // paddingLeft: "72px",
  // paddingRight: "72px",
}

const textGroupStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  paddingLeft: "72px",
  paddingRight: "72px",
  boxSizing: "border-box",
}

const textGroupMobileStyle = {
  paddingLeft: "48px",
  paddingRight: "48px",
}

const rightImageSectionStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
}

const buttonContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginTop: 36,
  marginBottom: 36,
}

const joinDiscordTextStyle = {
  marginRight: 12,
  color: "#ffffff",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "33px",
  letterSpacing: 1,
}

const imageStyle = {
  width: "100%",
  objectFit: "cover",
}

const imageMobileStyle = {
  width: "auto",
  height: "100%",
}

const DiscordButton = ({ url }) => (
  <a
    href={url}
    target="_blank"
    rel="noreferrer"
    style={{
      color: "white",
      fontWeight: "bold",
      borderRadius: 8,
      display: "inline-flex",
      alignItems: "center",
      padding: "10px 15px",
      backgroundColor: "#7289da",
      textDecoration: "none"
    }}
  >
    <span style={joinDiscordTextStyle}>Join</span>
    <img
      src={DiscordImage}
      alt="Discord Logo"
      width={146}
      height={40}
    />
  </a>
)

const IndexPage = () => {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth < 1200

  if (!windowWidth) {
    return <></>
  }

  const responsivePrimaryTitleTextStyle = Object.assign({}, primaryTitleTextStyle, isMobile ? primaryTitleTextMobileStyle : {})
  const responsiveSecondayTitleTextStyle = Object.assign({}, secondaryTitleTextStyle, isMobile ? secondaryTitleMobileTextStyle : {})

  const sectionTwoSecondaryContentTextStyle = Object.assign({}, contentTextStyle, {
    fontSize: "18px",
  })

  const sectionThreeSecondaryTitleTextStyle = Object.assign({}, responsiveSecondayTitleTextStyle, {
    color: "#f0ae01",
  })

  const sectionThreeContentTextStyle = Object.assign({}, contentTextStyle, {
    color: "#f0ae01",
  })

  const sectionFourSecondaryTitleTextStyle = Object.assign({}, responsiveSecondayTitleTextStyle, {
    color: "#ffffff",
  })

  const sectionFourContentTextStyle = Object.assign({}, contentTextStyle, {
    color: "#ffffff",
  })

  const sectionOneStyle = Object.assign({}, sectionStyle, {
    backgroundColor: "#a6e6fe",
  })

  const sectionTwoStyle = Object.assign({}, sectionStyle, {
    backgroundColor: "#f1a329",
  })

  const sectionThreeStyle = Object.assign({}, sectionStyle, {
    backgroundColor: "#111830",
  })

  const sectionFourStyle = Object.assign({}, sectionStyle, {
    minHeight: 400,
    backgroundColor: "#085738",
  })

  const responsiveSectionOneStyle = isMobile ? Object.assign({}, sectionOneStyle, sectionMobileStyle) : sectionOneStyle
  const responsiveSectionTwoStyle = isMobile ? Object.assign({}, sectionTwoStyle, sectionMobileStyle) : sectionTwoStyle
  const responsiveSectionThreeStyle = isMobile ? Object.assign({}, sectionThreeStyle, sectionMobileStyle) : sectionThreeStyle

  const responsiveLeftTextSectionStyle = isMobile ? Object.assign({}, leftTextSectionStyle, leftTextSectionMobileStyle) : leftTextSectionStyle
  const responsiveTextGroupStyle = Object.assign({}, textGroupStyle, isMobile ? textGroupMobileStyle : {})

  const responsiveImageStyle = Object.assign({}, imageStyle, isMobile ? imageMobileStyle : {})

  return (
    <main style={pageStyles}>
      <title>My Garage</title>
      <section style={responsiveSectionOneStyle}>
        <div style={responsiveLeftTextSectionStyle}>
          <div style={responsiveTextGroupStyle}>
            <div style={titleContainerStyle}>
              <img
                src={GarageLogo}
                alt="Garage Logo"
                width={isMobile ? 48 : 76}
                height={isMobile ? 48 : 76}
              />
              <span style={responsivePrimaryTitleTextStyle}>
                GARAGE
              </span>
            </div>
            <span style={contentTextStyle}>
              Garage is a distributed Web 3.0 community with a mission to acquire and operate a Formula 1 race team.
            </span>
            <span style={contentTextStyle}>
              Garage is en route to delivering the most influential car community to the world, run by engaged and distributed members to fund and manage the greatest automotive experiences in exchange for closer connections to our automotive heroes.
            </span>
          </div>
        </div>
        <div style={rightImageSectionStyle}>
          <img
            src={FerrariImage}
            alt="Ferrari"
            style={imageStyle}
          />
        </div>
      </section>
      <section style={responsiveSectionTwoStyle}>
        {!isMobile && (
          <div style={rightImageSectionStyle}>
            <img
              src={InteriorImage}
              alt="Countach"
              style={imageStyle}
            />
          </div>
        )}
        <div style={responsiveLeftTextSectionStyle}>
          <div style={responsiveTextGroupStyle}>
            <span style={responsiveSecondayTitleTextStyle}>
              THE CLUB
            </span>
            <span style={contentTextStyle}>
              Our members invest in membership tokens on the Blockchain to cement our access to the Garage Community where we collectively:
            </span>
            <span style={sectionTwoSecondaryContentTextStyle}>
              <li>
                develop networks deep in the racing and automotive worlds;
              </li>
            </span>
            <span style={sectionTwoSecondaryContentTextStyle}>
              <li>
                pool the capital necessary to acquire and operate an F1 team and other automotive properties selected by the community, like INDYCAR and WRC teams, custom builds, and museum-worthy collections;
              </li>
            </span>
            <span style={sectionTwoSecondaryContentTextStyle}>
              <li>
                make critical decisions together for our automotive properties; and
              </li>
            </span>
            <span style={sectionTwoSecondaryContentTextStyle}>
              <li>
                bring private, backstage content from F1 and other automotive properties to the fore.
              </li>
            </span>
          </div>
        </div>
        {isMobile && (
          <div style={rightImageSectionStyle}>
            <img
              src={InteriorImage}
              alt="Countach"
              style={imageStyle}
            />
          </div>
        )}
      </section>
      <section style={responsiveSectionThreeStyle}>
        <div style={responsiveLeftTextSectionStyle}>
          <div style={responsiveTextGroupStyle}>
            <span style={sectionThreeSecondaryTitleTextStyle}>
              THE TOKEN
            </span>
            <span style={sectionThreeContentTextStyle}>
              Over the next several years, Garage will mint membership tokens in limited series to support collective Club initiatives and cultivate our community.
            </span>
            <span style={sectionThreeContentTextStyle}>
              Each membership token represents exquisite automotive art in the Naked Style, developed and captured by pioneering photographer Ian Wood over multiple years in collaboration with the most significant car collections in the world.
            </span>
          </div>
        </div>
        <div style={rightImageSectionStyle}>
          <img
            src={GT40FrointImage}
            alt="GT40 Froint"
            style={responsiveImageStyle}
          />
        </div>
      </section>
      <section style={responsiveSectionTwoStyle}>
        {!isMobile && (
          <div style={rightImageSectionStyle}>
            <img
              src={InteriorImage}
              alt="Countach"
              style={imageStyle}
            />
          </div>
        )}
        <div style={responsiveLeftTextSectionStyle}>
          <div style={responsiveTextGroupStyle}>
            <span style={responsiveSecondayTitleTextStyle}>
              WHY
            </span>
            <span style={contentTextStyle}>
              The chance to own something that you can't afford.
            </span>
            <span style={contentTextStyle}>
              The opportunity to experience something that you can't access.
            </span>
            <span style={contentTextStyle}>
              It's time to redefine an industry with friends.
            </span>
          </div>
        </div>
        {isMobile && (
          <div style={rightImageSectionStyle}>
            <img
              src={InteriorImage}
              alt="Countach"
              style={imageStyle}
            />
          </div>
        )}
      </section>
      <section style={sectionFourStyle}>
        <div style={responsiveLeftTextSectionStyle}>
          <div style={responsiveTextGroupStyle}>
            <span style={sectionFourSecondaryTitleTextStyle}>
              HOW
            </span>
            <span style={sectionFourContentTextStyle}>
              Become part of this journey by joining our Discord.
            </span>
            <div style={buttonContainerStyle}>
              <DiscordButton url={"https://discord.gg/47rxxHeVUs"} />
            </div>
            <span style={sectionFourSecondaryTitleTextStyle}>
              WHEN
            </span>
            <span style={sectionFourContentTextStyle}>
              March 2022 - “Lights out, and away we go.”
            </span>
          </div>
        </div>
        <div style={rightImageSectionStyle}></div>
      </section>
    </main>
  )
}

export default IndexPage
